import { NavLink } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="notfound">
      <h1>404 - du er nå beyond:innhold</h1>
      <p>
        Rykk tilbake til <NavLink to="/">start</NavLink>
      </p>
    </div>
  );
};

export default NotFound;
