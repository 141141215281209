import {NavLink} from "react-router-dom";

export const LinkComponent = ({value, children}) => {

  if(value.type === 'internal') {
    return (
      <NavLink
        to={`/${value.internalLink.slug.current}`}
        target={(value.blank ? '_blank' : null)}
        rel={(value.blank ? 'noreferrer' : null)}
      >
        {children}
      </NavLink>
    )
  }

  if(value.type === 'external' && value.url) {
    return (
      <NavLink
         to={value.url}
         target={(value.blank ? '_blank' : null)}
         rel={(value.blank ? 'noreferrer' : null)}
      >
        {children}
      </NavLink>
    )
  }

  if(value.type === 'phone' && value.phone) {
    return (
      <NavLink
         to={'tel:' + value.phone.replace(/\s/g, '')}
         target={(value.blank ? '_blank' : null)}
         rel={(value.blank ? 'noreferrer' : null)}
      >
        {children}
      </NavLink>
    )
  }

  if(value.type === 'email' && value.email) {
    return (
      <NavLink
         to={'mailto:' + value.email.replace(/\s/g, '')}
         target={(value.blank ? '_blank' : null)}
         rel={(value.blank ? 'noreferrer' : null)}
      >
        {children}
      </NavLink>
    )
  }

  return (
      <span dangerouslySetInnerHTML={{
          __html: `<!-- \n Cannot render link type '` + value.type + `'\n`
            + JSON.stringify(value, null) + ` -->`
        }}
      />
  )
}
