import { useParams } from "react-router-dom";
import NotFound from "./notfound";
import { client } from "../utils/sanity/client";
import { useQuery } from "@tanstack/react-query";
import ArticleList from "./articlelist";
import LogoList from "./logolist";
import { Spinner } from "./spinner";
import HighlightComponent from "./highlight";
import { MainBody } from "./mainbody";

async function getContent(slug) {
  const CONTENT_QUERY = `*[(_type == "article" || _type == "author") && slug.current == '${slug}'][0] {
    _type,
    title,
    pageHeading,
    description,
    highlight[],
    listingtitle,
    listing[]-> {
      _id,
      title,
      content,
      description,
      "imageUrl": image.asset->url,
      "imageCaption": image.caption,
    },
    simplelistingtitle,
    simplelistingdescription,
    simplelisting[]-> {
      _id,
      name,
      "imageUrl": image.asset->url,
    },
    "imageUrl": image.asset->url,
    "imageCaption": image.caption,
    content[] {
      ...,
      internalLink->{_type,slug,title},
      markDefs[]{
        ...,
        _type == "link" => {
          ...,
          internalLink->{_type,slug,title}
        }
      }
    },
  }`;
  const content = await client.fetch(CONTENT_QUERY);
  console.log("API CALL - PAGE", content.title);
  return content;
}

const DynamicPage = () => {
  const wildcard = useParams()['*']
  const activeSlug = wildcard.split('/').pop()

  const { isPending, error, data } = useQuery({
    queryKey: ["article", activeSlug],
    queryFn: async () => {
      const content = await getContent(activeSlug);
      if (!content) throw new Error("Siden finnes ikke");
      return content;
    },
    staleTime: 60 * 60 * 1000,
  });

  if (isPending) {
    return (
      <div className="center-text">
        <Spinner a11yText="Henter innholdet til siden" />
      </div>
    );
  }
  if (error) {
    console.log("Det oppstod en feil: " + error.message);
    return <NotFound />;
  }

  return (
    data && (
      <>
        {data.pageHeading && <h1>{data.pageHeading}</h1>}

        <div className="main-body">
          {data.description && <p>{data.description}</p>}
          {/* {data.imageUrl && (
            <img src={`${data.imageUrl}?w=900`} alt={data.imageCaption} />
          )} */}
          <MainBody content={data.content} />
          {data.highlight && <HighlightComponent items={data.highlight} />}
          <ArticleList items={data.listing} title={data.listingtitle} />
          <LogoList
            items={data.simplelisting}
            title={data.simplelistingtitle}
            intro={data.simplelistingdescription}
          />
        </div>
      </>
    )
  );
};

export default DynamicPage;
