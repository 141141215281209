import "./App.css";
import { Suspense } from "react";
import { NavLink, Routes, Route } from "react-router-dom";
import NavBar from "./components/navbar";
import Footer from "./components/footer";
import Home from "./components/home";
import Logo from "./components/logo";
import DynamicPage from "./components/dynamicpage";

const App = () => {
  return (
    <>
      <header className="branding">
        <div className="branding-logo">
          <NavLink to={"/"} style={{ display: "block" }}>
            <Logo />
          </NavLink>
        </div>
      </header>

      <NavBar />

      <main className="wrapper">
        <Suspense fallback={<div className="container">Laster...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/*"
              element={<DynamicPage />}
            />
          </Routes>
        </Suspense>
      </main>

      <Footer />
    </>
  );
};

export default App;
