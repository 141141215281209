import imageUrlBuilder from '@sanity/image-url'
import { client } from '../utils/sanity/client';

const imageBuilder = imageUrlBuilder(client)
const urlFor = (source) => imageBuilder.image(source)

export const ImageComponent = ({value}) => {
  return (
    <img
      src={urlFor(value).width(800).height(450).url()}
      alt={value.alt || ' '}
      loading="lazy"
    />
  )
}