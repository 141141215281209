import { client } from "../utils/sanity/client";
import { useQuery } from "@tanstack/react-query";
import { Spinner } from "./spinner";
import HighlightComponent from "./highlight";
import {MainBody} from "./mainbody";

async function getContent() {
  const CONTENT_QUERY = `*[_type == "sitesetting"][0] {
    startpage-> {
      pageHeading,
      description,
      content,
      highlight
    }
  }`;
  const content = await client.fetch(CONTENT_QUERY);
  console.log("API CALL - HOME");
  return content.startpage;
}

const Home = () => {
  const { isPending, error, data } = useQuery({
    queryKey: ["homepage"],
    queryFn: async () => {
      const content = await getContent();
      content.pageHeading = content.pageHeading.replace(
        "digger",
        `<strong>digger</strong>`
      );
      return content;
    },
    staleTime: 60 * 60 * 1000,
  });

  if (isPending) {
    return (
      <div className="center-text">
        <Spinner />
      </div>
    );
  }
  if (error) return "Det oppstod en feil: " + error.message;

  return (
    data && (
      <div className="home">
        <h1 dangerouslySetInnerHTML={{ __html: data.pageHeading }}></h1>
        {data.description && <p>{data.description}</p>}
        <MainBody content={data.content} />
        <HighlightComponent items={data.highlight[0]} />
      </div>
    )
  );
};

export default Home;
