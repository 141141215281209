import styles from "./highlight.module.css";
import {MainBody} from "./mainbody";

const HighlightComponent = (props) => {
  return (
    <div className={styles.highlight}>
      <MainBody content={props.items} />
    </div>
  );
};

export default HighlightComponent;
