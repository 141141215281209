import styles from "./spinner.module.css";

export const Spinner = ({ a11yText = "Laster..." }) => {
  return (
    <>
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        className={styles.spinner}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.7038 35.3733C26.2693 34.4096 29.4518 32.373 31.82 29.5402C32.2676 29.0162 32.5379 28.3362 32.5379 27.5931C32.5379 25.9364 31.1944 24.5934 29.5371 24.5934C28.5842 24.5934 27.735 25.0374 27.1853 25.7298C25.6096 27.5997 23.4997 28.9443 21.1376 29.5828C18.7545 30.2269 16.2307 30.1208 13.91 29.279C11.5893 28.4373 9.58473 26.9007 8.16934 24.8787C6.75395 22.8567 5.99658 20.4476 6.00046 17.9797C6.00432 15.5119 6.76925 13.1052 8.191 11.0876C9.61274 9.07009 11.6222 7.53982 13.9455 6.70532C16.2687 5.87083 18.7929 5.7727 21.174 6.42431C23.5131 7.06442 25.6024 8.39747 27.1677 10.2465C27.7168 10.9517 28.574 11.4053 29.5371 11.4053C31.1944 11.4053 32.5379 10.0623 32.5379 8.40559C32.5379 7.69232 32.2888 7.03721 31.873 6.52237L31.8738 6.52168C29.5127 3.67033 26.3297 1.61603 22.7583 0.63871C19.187 -0.338614 15.4011 -0.191432 11.9165 1.0602C8.43183 2.31183 5.41797 4.60704 3.28554 7.63308C1.15312 10.6591 0.00582921 14.2688 2.21543e-05 17.9703C-0.0057849 21.6718 1.13014 25.2851 3.25305 28.3178C5.37597 31.3506 8.38261 33.6552 11.8633 34.9178C15.344 36.1803 19.1294 36.3394 22.7038 35.3733Z"
          fill="#459181"
        />
      </svg>
      <p className="visually-hidden">{a11yText}</p>
    </>
  );
};
