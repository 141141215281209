import styles from "./articlelist.module.css";
import { MainBody } from "./mainbody";

const ArticleList = ({ items, title }) => {
  const Heading = () => {
    return title && <h2>{title}</h2>;
  };

  const List = () => {
    return (
      items &&
      items.length && (
        <div className={styles["article-list"]}>
          {items.map((item, index) => (
            <article key={item._id} className={styles.article}>
              {item.imageUrl && (
                <figure className={styles.figure}>
                  <picture className={styles.picture}>
                    <img
                      src={`${item.imageUrl}?w=350&q=60`}
                      loading={index > 0 ? "lazy" : null}
                      fetchPriority={index > 0 ? null : "high"}
                      width="300"
                      height="300"
                      alt=""
                    />
                  </picture>
                </figure>
              )}
              <h2 className={styles.heading}>{item.title}</h2>
              {item.description && <p>{item.description}</p>}
              {item.content && (
                <div className={styles["bio"]}>
                  <MainBody content={item.content} />
                </div>
              )}
            </article>
          ))}
        </div>
      )
    );
  };

  return (
    <>
      <Heading />
      <List />
    </>
  );
};

export default ArticleList;
