import styles from "./logolist.module.css";

const LogoList = ({ items, title, intro }) => {
  const Heading = () => {
    return title && <h2>{title}</h2>;
  };

  const Intro = () => {
    return intro && <p>{intro}</p>;
  };

  const List = () => {
    return (
      items &&
      items.length && (
        <ul className={styles["logo-list"]}>
          {items.map((item) => (
            <li key={item._id} className={styles["logo-list__item"]}>
              {item.imageUrl && (
                <img
                  src={`${item.imageUrl}?h=54`}
                  alt={item.name}
                  title={item.name}
                />
              )}
            </li>
          ))}
        </ul>
      )
    );
  };

  return (
    <>
      <Heading />
      <Intro />
      <List />
    </>
  );
};

export default LogoList;
