import { client } from "../utils/sanity/client";
import { useQuery } from "@tanstack/react-query";
import { Spinner } from "./spinner";
import styles from "./footer.module.css";
import {MainBody} from "./mainbody";

async function getContent() {
  const CONTENT_QUERY = `
    *[_type=="sitesetting"][0] {
      footer
    }
  `;
  const content = await client.fetch(CONTENT_QUERY);
  console.log("API CALL - FOOTER");
  return content;
}

const Footer = () => {
  const { isPending, error, data } = useQuery({
    queryKey: ["footer"],
    queryFn: async () => {
      const content = await getContent();
      return content;
    },
    staleTime: 5 * 60 * 1000,
  });

  if (isPending) {
    return (
      <footer className="footer">
        <div className="wrapper">
          <Spinner a11yText="Laster inn footer" />
        </div>
      </footer>
    );
  }

  if (error) return "Det oppstod en feil: " + error.message;

  return (
    <footer className={styles.footer}>
      <div className={styles["footer__line"]}></div>
      <div className={`wrapper ${styles["footer__wrapper"]}`}>
        {data && data.footer && <MainBody content={data.footer} /> }
      </div>
    </footer>
  );
};

export default Footer;
