import {PortableText} from "@portabletext/react";
import {ImageComponent} from "./image";
import {LinkComponent} from "./link";

export const MainBody = ({content}) => {
  return (
    <PortableText
      value={content}
      components={{
        marks: {
          link: ({value, children}) => {
            return <LinkComponent value={value} children={children}/>
          },
        },
        types: {
          image: ImageComponent,
        },
      }}
    />
  )
};
