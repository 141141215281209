import { NavLink } from "react-router-dom";
import { client } from "../utils/sanity/client";
import { useQuery } from "@tanstack/react-query";
import styles from "./navbar.module.css";
import { Spinner } from "./spinner";

async function getContent() {
  const CONTENT_QUERY = `
    *[_type=="sitesetting"][0] {
      menu[]-> {
        "slug": slug.current,
        title,
        submenu[]->{
          "slug": slug.current,
          title,
          submenu[]->{
            "slug": slug.current,
            title,
            submenu[]->{
              "slug": slug.current,
              title,
            },
          },
        },
      }
    }
  `;
  const content = await client.fetch(CONTENT_QUERY);
  console.log("API CALL - NAV");
  return content;
}

const NavBar = () => {
  const { isPending, error, data } = useQuery({
    queryKey: ["navbar"],
    queryFn: async () => {
      const content = await getContent();
      return content;
    },
    staleTime: 5 * 60 * 1000,
  });

  if (isPending) {
    return (
      <div className={styles["navbar-wrapper"]}>
        <div className="wrapper">
          <Spinner a11yText="Laster inn meny" />
        </div>
      </div>
    );
  }
  if (error) return "Det oppstod en feil: " + error.message;

  function MenuList({ menu, prefix, includeHome }) {
    if (menu) {
      return (
        <ul className={styles["navbar"]}>
          {menu.map((item) => (
            <li key={item.slug} className={styles["navbar__item"]}>
              <NavLink
                to={`${prefix}/${item.slug}`}
                // className={styles["navbar__link"]}
                className={({ isActive }) =>
                  isActive
                    ? styles["navbar__link--active"]
                    : styles["navbar__link"]
                }
              >
                {item.title || item.name}
              </NavLink>
              <MenuList menu={item.submenu} prefix={prefix + "/" + item.slug} />
            </li>
          ))}
        </ul>
      );
    }
  }

  return (
    <div className={styles["navbar-wrapper"]}>
      <div className="wrapper">
        <nav aria-label="Meny">
          <MenuList menu={data.menu} prefix="" includeHome={true} />
        </nav>
      </div>
    </div>
  );
};

export default NavBar;
